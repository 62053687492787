import React from 'react';
import wartungImg from './Images/WartungImg.png'
import './Wartungsseite.css'

function Wartungsseite() {
  return (
    <div className='w-bg'>
    <div className='wartungsseite-bg-top'/>
    <div className='wartungsseite'>
      <h1 className='wartung-title'>Site Under Maintenance</h1>
      <p className='wartung-sub-title'>The Resolution for tablet is currently under maintenance. Please visit us from a desktop or mobile for full access.</p>
      <img className='wartungsImg' src={wartungImg} alt=''/>
    </div>
    <div className='wartungsseite-bg-bottom'/>
    </div>
  );
}

export default Wartungsseite;