import ReactGA from 'react-ga';

const initGA = () => {
  ReactGA.initialize('G-BRE7XW6EB5', {
    gaOptions: {
      anonymizeIp: true,
      cookieExpires: 63072000, // Ablaufzeit in Sekunden (2 Jahre)
      cookieFlags: 'SameSite=None; Secure'
    },
  });
};

export const trackPageView = (path) => {
  ReactGA.pageview(path);
};

export default initGA;