import React from 'react'
import "./Navbar.css";
import { useState } from 'react';
import  Button  from "../button/Button"
import  Dropdown  from "../dropdown/Dropdown"
import { Link } from "react-router-dom"
import logo from "../../brainacs-logo-orange.svg"
import contactUsIcon from "../navbar/ContactUsIconNavbar.svg"

import { useLocation } from 'react-router-dom';


const Navbar = () => {
    const dropdown = true;
    const [click, setClick] = useState(false);
    const [drop, setDrop] = useState(false);
    //const [navbar, setNavbar] = useState(false);
    const handleDrop = () => {
        setDrop(!drop);
    }

    const handleclick = () => {
        setClick(!click);
    }

    const closeMobileMenu = () => {
        setClick(false);
    }
/*
    const handleNavbar = (event) => {
        event.preventDefault();
       setNavbar(event.currentTarget.navbar);
    }
*/
    const location = useLocation().pathname;
/*
    const changeNavbar = () => {
        if(window.scrollY >= 80 && location === "/" ){
            setNavbar(true); 
        }    else {
           
            setNavbar(false);     
            }
        
    }
    window.addEventListener('scroll', changeNavbar);
*/
  return (
    <>
        <nav className='navbar-container' >
            <Link to="/" className='navbar-logo'>
                <img className='logo-width' src={logo} alt='Logo'/>
            </Link>
            <div className='menu-icon' onClick={handleclick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars' } />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu' } >
                <li className='nav-item' >
                    <Link to='/' className='nav-links' onClick={closeMobileMenu} >
                        Home
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/Über-uns' className='nav-links' onClick={closeMobileMenu}>
                        Über uns
                    </Link>
                </li>
                { 
                !dropdown &&
                <li className='nav-item' onClick={handleDrop}>
                    <Link to={location} className='nav-links'>
                    <i className={drop ? "fa-solid fa-chevron-up fa-2xs": "fa-solid fa-chevron-down fa-2xs"}></i> Themengebiete 
                    </Link>
                    {drop && <Dropdown />}
                </li>
                }
                <li className='nav-item'>
                    <Link to='/Themengebiete' className='nav-links' onClick={closeMobileMenu}>
                        Themengebiete
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/Leistungen' className='nav-links' onClick={closeMobileMenu}>
                        Leistungen
                    </Link>
                </li>
            </ul>
            <div className='button-margin'>
            {
                <Button />
            }
            {
                <div className='icon-headset'>
                    <Link to="/Kontaktiere-Uns">
                        <img className='contact-us-icon' src={contactUsIcon} alt=''/>
                    </Link>
                </div>
            }
            </div>
        </nav>
    </>
  )
}

export default Navbar