import React from "react";
import "./Button.css";
import { Link } from 'react-router-dom';

const Button = () => {
    return(
        <div className="btn-container">
        <Link to="/Kontaktiere-Uns">
            <button className="btn">
                Kontaktiere uns
            </button>
        </Link>
        </div>
    )
}

export default Button