import React, { lazy, Suspense, useState, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import './App.css';
import Navbar from "./components/navbar/Navbar"
import CookieConsent from 'react-cookie-consent';
import { HashRouter as Router, Link, useLocation } from "react-router-dom"
import { Route, Routes } from 'react-router-dom';
import initGA, { trackPageView } from './initGA';
import Wartungsseite from './pages/wartungsseite/Wartungsseite';

const Home = lazy(() => import("./pages/home/Home"))
const Seminar = lazy(() => import("./pages/seminar/Seminar"))
const Kontakt = lazy(() => import("./pages/Kontakt/Kontakt"))
const Themengebiete = lazy(() => import("./pages/themengebiete/Themengebiete"))
const AboutUs = lazy(() => import("./pages/aboutus/AboutUs"))
const Leistungen = lazy(() => import("./pages/leistungen/Leistungen"))
const Impressum = lazy(() => import("./pages/impressum/Impressum"))
const Datenschutz = lazy(() => import("./pages/datenschutz/Datenschutz"))

const Umsatzsteuerrecht = lazy(() => import("./pages/schulungen/RechtDatenschutz/Umsatzsteuerrecht"))
const Bilanzsteuerrecht = lazy(() => import("./pages/schulungen/RechtDatenschutz/Bilanzsteuerrecht"))
const Compliance = lazy(() => import("./pages/schulungen/RechtDatenschutz/Compliance"))
const StrategischesDenkenPerformance = lazy(() => import("./pages/schulungen/persoenlicheEntwicklung/StrategischesDenkenPerformance"))
const AgilitätScrum = lazy(() => import("./pages/schulungen/persoenlicheEntwicklung/AgilitätScrum"))
const StrategischZumErfolgmitOKR = lazy(() => import("./pages/schulungen/persoenlicheEntwicklung/StrategischZumErfolgmitOKR"))

const EmployerBrandingTopic = lazy(() => import("./pages/schulungen/personalwesen/EmployerBrandingTopic"))
const DigitaleTransformation = lazy(() => import("./pages/schulungen/personalwesen/DigitaleTransformationTopic"))
const Payroll = lazy(() => import("./pages/schulungen/personalwesen/PayrollTopic"))
const Talentmanagement = lazy(() => import("./pages/schulungen/personalwesen/TalentmanagementTopic"))
const HrControlling = lazy(() => import("./pages/schulungen/personalwesen/HrControllingTopic"))
const HrStrategie = lazy(() => import("./pages/schulungen/personalwesen/HrStrategieTopic"))

const CyberSecurity = lazy(() => import("./pages/schulungen/technology/CyberSecurityTopic"))
const DatenDynamik = lazy(() => import("./pages/schulungen/technology/DatenDynamikTopic"))
const MicroServices = lazy(() => import("./pages/schulungen/technology/MicroServices"))
const MicroFrontends = lazy(() => import("./pages/schulungen/technology/MicroFrontends"))

const GreenArchitecture = lazy(() => import("./pages/schulungen/architecture/GreenArchitectureTopic"))
const Logistikpower = lazy(() => import("./pages/schulungen/bauwesen/LogistikpowerTopic"))
const Waehrungsrisiken = lazy(() => import("./pages/schulungen/finance/WaehrungsrisikenTopic"))
const WinWinStrategien = lazy(() => import("./pages/schulungen/marketingAndCommunication/WinWinStrategienTopic"))
const HighPerformance = lazy(() => import("./pages/schulungen/persoenlicheEntwicklung/HighPerformanceTopic"))
const PraezisionImMaschinenbau = lazy(() => import("./pages/schulungen/maschinenbau/PraezisionImMaschinenbauTopic"))

function App() {
  const customHistory = createBrowserHistory();
  const [gaInitialized, setGaInitialized] = useState(false);

  const handleAcceptCookies = () => {
    if (!gaInitialized) {
      initGA();
      setGaInitialized(true);
    }
  };

  const [isIpad, setIsIpad] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      // Überprüfe, ob die Bildschirmbreite kleiner als eine bestimmte Breite ist (z.B. 768px)
      if (window.innerWidth >= 440 && window.innerWidth <= 1160) {
        setIsIpad(true);  // Wartungsseite anzeigen (iPad-Resolution)
    } else {
        setIsIpad(false);  // Normale Seite anzeigen (alles unter 600px und über 1160px)
    }
    };

    checkScreenSize();
    // Event Listener für die Bildschirmgröße hinzufügen
    window.addEventListener('resize', checkScreenSize);

    // Event Listener beim Verlassen der Komponente entfernen
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);


  useEffect(() => {
    // Überprüfen, ob der Benutzer bereits der Verwendung von Cookies zugestimmt hat
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'true') {
      handleAcceptCookies();
    }
  }, []);

  useEffect(() => {
    if (gaInitialized) {
      // Lausche auf Route-Änderungen
      const unlisten = customHistory.listen((location) => {
        trackPageView(location.pathname + location.search); // Tracke die neue Route
        console.log(location.pathname + location.search)
      });
      // Cleanup-Funktion
      return () => {
        unlisten();
      };
    }
  }, [gaInitialized]);


  if (isIpad) {
    return <Wartungsseite />;
  }

  return (
    <React.Fragment>
    <Router>
      <CookieConsent
        onAccept={() => {
          localStorage.setItem('cookieConsent', 'true');
          handleAcceptCookies();
        }}
        containerClasses='containerCookieClass'
        buttonText="Zustimmen"
        buttonClasses="buttonCookie"
        buttonWrapperClasses="buttonCookieWrapper"
        declineButtonClasses="declineButtonCookie"
        declineButtonText="Ablehnen"
        enableDeclineButton
        buttonStyle={{ background: "#ffa500", fontWeight: "400", color: "white"}}
        onDecline={() => localStorage.setItem('cookieConsent', 'false')}
      >
        Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern. Durch die Nutzung unserer Website stimmen Sie unserer 
        <Link to="/Datenschutz" target="_blank" className='link-cookies'> Cookie-Policy</Link>
        <span> </span>zu.
      </CookieConsent>
      <Navbar />
        <Suspense fallback={<Loading />}>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Seminar" element={<Seminar />} />
              <Route path="/Kontaktiere-Uns"  element={<Kontakt />} />
              <Route path="/Themengebiete"  element={<Themengebiete />} />
              <Route path="/Über-uns"  element={<AboutUs />} />
              <Route path="Leistungen"  element={<Leistungen />} />
              <Route path="/Impressum"  element={<Impressum />} />
              <Route path="/Datenschutz"  element={<Datenschutz />} />
              
              <Route path="/Employer-Branding"  element={<EmployerBrandingTopic />} />
              <Route path="/Digitale-Transformation-im-HR"  element={<DigitaleTransformation />} />
              <Route path="/Talentmanagement-und-Nachfolgeplanung"  element={<Talentmanagement />} />
              <Route path="/HR-Controlling-und-Datenanalyse"  element={<HrControlling />} />
              <Route path="/Grundlagen-der-Entgeltabrechnung"  element={<Payroll />} />
              <Route path="/HR-Strategie-&-Personalmanagement"  element={<HrStrategie />} />

              <Route path="/Cyber-Security"  element={<CyberSecurity />} />
              <Route path="/Daten-Dynamik"  element={<DatenDynamik />} />
              <Route path="/Micro-Services"  element={<MicroServices />} />
              <Route path="/Micro-Frontends"  element={<MicroFrontends />} />

              <Route path="/Logistikpower-am-Bau"  element={<Logistikpower />} />
              <Route path="/Währungsrisiken-meistern"  element={<Waehrungsrisiken />} />
              <Route path="/Präzision-im-Maschinenbau"  element={<PraezisionImMaschinenbau />} />
              <Route path="/Win-Win-Strategien"  element={<WinWinStrategien />} />
              <Route path="/High-Performance-Fit-durchs-Berufsleben"  element={<HighPerformance />} />
              <Route path="/Green-Architecture-entdecken"  element={<GreenArchitecture />} />
							
							<Route path="/Umsatzsteuerrecht"  element={<Umsatzsteuerrecht />} />
							<Route path="/Bilanzsteuerrecht"  element={<Bilanzsteuerrecht />} />
							<Route path="/Compliance"  element={<Compliance />} />
							<Route path="/Strategisch-zum-Erfolg-mit-OKR"  element={<StrategischZumErfolgmitOKR />} />
							<Route path="/Agilität"  element={<AgilitätScrum />} />
							<Route path="/Strategisches-Denken"  element={<StrategischesDenkenPerformance />} />
              
          </Routes>
        </Suspense>
    </Router>
    </React.Fragment>
  );
}


const Loading = () => {
  return (
  <div className='loader'>
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
  </div>
  )
};

export default App;
